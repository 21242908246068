'use client';

import Block from 'namestation-ui/lib/Components/Block';
import Button from 'namestation-ui/lib/Components/Button';
import Buttons from 'namestation-ui/lib/Components/Buttons';
import LinkWrapper from '@/components/Layout/link-wrapper';
import { urls } from 'data/urls';
import { Suspense, useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { trackPageView } from 'common/customFunctions';

const Custom404 = () => {
  const pathname = usePathname();

  const SearchParamsHandler = () => {
    const searchParams = useSearchParams();

    useEffect(() => {
      const notFoundPath = `/notfound?path=${pathname}${searchParams?.toString() ? `?${searchParams.toString()}` : ''}`;
      trackPageView(notFoundPath);
    }, [searchParams]);

    return null;
  };

  const content = (
    <Block
      height="window"
      className="gradient-background align-center public-front"
    >
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist or has been moved.</p>

      <Buttons layout="centered">
        <Button
          title="Go back home"
          link={LinkWrapper}
          href={urls.home}
          intent="primary"
          type="anchor"
        />
      </Buttons>
    </Block>
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SearchParamsHandler />
      {content}
    </Suspense>
  );
};

export default Custom404;
